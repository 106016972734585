import React, { ChangeEvent, useMemo } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InputMask from 'react-input-mask';
import { Typography } from '@material-ui/core';
import {
  ConditionsAnswers,
  ConditionsAnswersWithCC,
  ConditionsAnswersWithNA,
  ConditionsAnswersWithNN,
  QuestionType,
  Questions,
} from '../schemas/types';
import {
  checkIfDate,
  combineDate,
  getAnswersByQuestionId,
  getCalculation,
  getParsedAnswers,
  getParsedTemplate,
  getRadioOptions,
} from 'utils/report-writing';
import { Editor } from 'tinymce';
import { ReportRichEditor } from 'components';
import { DeleteIcon, Time } from 'icons';
import { CompanyTypesEnum, Contact, Country, Input, ReportTypesEnum } from 'types';
import { getDateWithoutTimeZone, toDateWithTimeZone } from 'utils';
import { getEntityOptionName } from 'utils/common';
import { ReactComponent as HistoryIcon } from 'icons/history.svg';

import { validRadioQuestionTypes } from '../const';
import { useGetContacts, useGetContractors, useGetCountries } from 'lib/react-query/queries';
import { useQuestionTypesHandlers } from './useQuestionHandlers';
import styles from '../ReportWriting.module.scss';

type QuestionTypesHandlersProps = {
  // item: Questions;
  questionsState: Questions[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  errors: string[];
  section: string;
  handleDeletePermit: (idx: string) => void;
  addPermit: () => void;
  setQuestionsState: (value: React.SetStateAction<Questions[]>) => void;
  mathematicalExpHistory: { [key: string]: string };
  setMathematicalExpHistory: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  addRef: (ref: React.RefObject<Editor>) => void;
  handleOpenLookBack: (id: number) => void;
  reportType: string;
};

const TimeInput = (props: JSX.IntrinsicAttributes & TextFieldProps) => (
  <TextField
    {...props}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Time className={styles.clock} />
        </InputAdornment>
      ),
    }}
  />
);

const QuestionLabel = ({
  children,
  question,
  hidden,
  required,
  lookBack,
  onClickLookBack,
  id,
}: {
  id?: string | number;
  children: React.ReactNode;
  question?: string;
  hidden?: boolean;
  required?: boolean;
  lookBack?: boolean;
  onClickLookBack?: (id: number) => void;
}) => (
  <div>
    {!hidden && (
      <>
        <p className="mb-8 p-relative pr-24">
          {question}
          {required && <span className={styles.asterisk}>*</span>}
          {lookBack && (
            <HistoryIcon
              className={styles.lookBackIcon}
              width={25}
              height={23}
              onClick={() => onClickLookBack && onClickLookBack(id as number)}
            />
          )}
        </p>
      </>
    )}
    {children}
  </div>
);

export const useSetupQuestions = ({
  questionsState,
  setQuestionsState,
  setErrors,
  errors,
  section,
  handleDeletePermit,
  mathematicalExpHistory,
  setMathematicalExpHistory,
  addRef,
  addPermit,
  handleOpenLookBack,
  reportType,
}: QuestionTypesHandlersProps) => {
  const { data: countries } = useGetCountries({
    options: { enabled: section === '50' || section === '51' || section === '31' || section === '32' },
  });
  const { data: contractors } = useGetContractors();
  const { data: contacts } = useGetContacts();

  const mappedStates = useMemo(() => {
    if (!countries?.records?.length) return [];
    //eslint-disable-next-line
    return countries?.records.map((el: any, i: number) => ({ ...el, name: el.fields['ste_name'], id: i }));
  }, [countries]);
  const filteredContractors = useMemo(() => (contractors?.length ? contractors : []), [contractors]);
  const filteredContacts = useMemo(
    () => (contacts?.length ? contacts.filter(({ companyType }) => companyType !== CompanyTypesEnum.Vendor) : []),
    [contacts],
  );

  //temporary fix of bug CP-1588
  const addDateRelationForInspection = (item: Questions, date: MaterialUiPickersDate) => {
    if (item.question === 'Time of inspection:') {
      const inspectionDateAnswear = questionsState.find((question) => question.id === '138')?.answer;
      const answerValue = inspectionDateAnswear
        ? combineDate(inspectionDateAnswear, getDateWithoutTimeZone(date))
        : getDateWithoutTimeZone(date) ?? '';
      item.answer = answerValue;
      return setQuestionsState([...questionsState]);
    }

    if (item.question === 'Inspection Date:') {
      const inspectionTimeAnswear = questionsState.find((question) => question.id === '139')?.answer;
      item.answer = getDateWithoutTimeZone(date) ?? '';
      const newQuestionsState = questionsState.map((question) => {
        if (question.id === '139') {
          return item.answer && inspectionTimeAnswear
            ? {
                ...question,
                answer: combineDate(item.answer, inspectionTimeAnswear),
              }
            : question;
        }
        return question;
      });
      return setQuestionsState([...newQuestionsState]);
    }
  };

  const handleClearErrorById = (id: string) => setErrors((prevErrors) => prevErrors.filter((error) => error !== id));

  const getEditor = (option: Partial<Questions>, isObservation?: boolean) => {
    const value = getParsedTemplate(questionsState, option.template);

    const onChange = (value: string) => {
      option.template = value;
      setQuestionsState([...questionsState]);
    };

    return (
      <ReportRichEditor
        addRef={addRef}
        value={value ?? ''}
        onChange={onChange}
        isObservation={reportType === ReportTypesEnum.PreConstruction || isObservation}
      />
    );
  };

  const getQuestions = (item: Questions) => {
    const {
      answer,
      type,
      id,
      isAmount,
      hidden,
      options,
      dateFormat,
      question,
      isCalculation,
      isPercentage,
      allowEditorToEdit,
      disabled,
      withoutCalculation,
      required,
      lookBack,
    } = item;
    const { handleBlurPlainQuestion, handleChangePlainQuestion, handleFocusPlainQuestion } = useQuestionTypesHandlers({
      item,
      questionsState,
      setQuestionsState,
      mathematicalExpHistory,
      setMathematicalExpHistory,
    });
    let questionItem: React.ReactNode;
    const isError = errors.includes(id as string);

    if (validRadioQuestionTypes.includes(type)) {
      const parsedAnswer = getParsedAnswers(questionsState, answer);
      const option = options?.[parsedAnswer]?.[0];
      questionItem = (
        <>
          {!hidden && (
            <FormControl component="fieldset" className="mb-16" error={isError}>
              <RadioGroup
                aria-label="radio-select"
                name="radio-select"
                value={parsedAnswer}
                onChange={(e) => {
                  item.answer = e.target.value;

                  if (id === '48' && e.target.value === ConditionsAnswers.No) {
                    const question = getAnswersByQuestionId('49', questionsState);
                    if (question) {
                      question.answer = ConditionsAnswersWithNA.NA;
                      question.isModified = true;
                    }
                  }

                  if (
                    id === '33' &&
                    (e.target.value === ConditionsAnswersWithNN.No ||
                      e.target.value === ConditionsAnswersWithNN.NotNeeded)
                  ) {
                    const question = getAnswersByQuestionId('37', questionsState);
                    if (question) {
                      question.answer = ConditionsAnswersWithCC.CantComment;
                      question.isModified = true;
                    }
                  }

                  setQuestionsState([...questionsState]);
                }}
              >
                {getRadioOptions(type, id)}
              </RadioGroup>
            </FormControl>
          )}
          {option && option.hasOwnProperty('template') && (
            <>
              {(function () {
                return getEditor(option, allowEditorToEdit);
              })()}
            </>
          )}
        </>
      );
    }
    if (type === QuestionType.PlainQuestion) {
      const option = options?.['']?.[0];
      questionItem = (
        <>
          {!hidden && (
            <TextField
              onBlur={(e) => handleBlurPlainQuestion(e)}
              onFocus={handleFocusPlainQuestion}
              disabled={disabled}
              value={getCalculation(
                getParsedAnswers(questionsState, answer, withoutCalculation),
                isCalculation,
                isPercentage,
                id,
              )}
              key={id}
              className="mb-16"
              label="Type here"
              onChange={(e) => handleChangePlainQuestion(e)}
              InputProps={{
                startAdornment:
                  (isAmount && (
                    <InputAdornment position="start" className="pl-8">
                      $
                    </InputAdornment>
                  )) ||
                  (isPercentage && (
                    <InputAdornment position="start" className="pl-8">
                      %
                    </InputAdornment>
                  )),
              }}
            />
          )}
          {option && option.hasOwnProperty('template') && (
            <QuestionLabel question={option.title} {...{ required }}>
              <>
                {(function () {
                  return getEditor(option, allowEditorToEdit);
                })()}
              </>
            </QuestionLabel>
          )}
        </>
      );
    }
    if (type === QuestionType.PhoneNumber) {
      questionItem = (
        <>
          <InputMask
            mask="+1(999)999-9999"
            value={answer}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              item.answer = !isNaN(parseInt(e.target.value.replace(/[^0-9]/g, '')))
                ? parseInt(e.target.value.replace(/[^0-9]/g, '')).toString()
                : '';
              setQuestionsState([...questionsState]);
            }}
          >
            {(inputProps: Input) => <TextField {...inputProps} className="mb-16" placeholder="+1(609)513-0514" />}
          </InputMask>
        </>
      );
    }
    if (type === QuestionType.Textarea) {
      const option = options?.[''][0];
      questionItem = (
        <>
          <TextField
            value={answer}
            key={id}
            multiline
            className="mb-16"
            label="Type here"
            onChange={(e) => {
              item.answer = e.target.value;
              setQuestionsState([...questionsState]);
            }}
          />
          {option && option.hasOwnProperty('template') && (
            <>
              {(function () {
                return getEditor(option, allowEditorToEdit);
              })()}
            </>
          )}
        </>
      );
    }
    if (type === QuestionType.MultiSelect) {
      questionItem = (
        <AutoComplete
          className="mb-16"
          id="combo-box-demo"
          multiple
          value={getParsedAnswers(questionsState, answer) ? getParsedAnswers(questionsState, answer)?.split(', ') : []}
          options={options ? (Object.keys(options) as string[]) : []}
          onChange={(event, value) => {
            item.answer = value.join(', ');
            setQuestionsState([...questionsState]);
          }}
          renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
        />
      );
    }

    if (type === QuestionType.CustomMultiSelect) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let filteredOptions = [] as any;
      const parsedAnswer: Contact[] = typeof answer === 'string' ? JSON.parse(answer) : answer;

      if (item.id === '201') {
        filteredOptions = filteredContacts.filter(({ id: idx }) => !parsedAnswer.some(({ id }) => id === idx));
      }

      if (item.id === '128') {
        filteredOptions = filteredContractors.filter(({ id: idx }) => !parsedAnswer.some(({ id }) => id === idx));
      }

      questionItem = (
        <AutoComplete
          className="mb-16"
          id="combo-box-demo"
          multiple
          getOptionLabel={getEntityOptionName}
          value={parsedAnswer as unknown as Contact[]}
          options={filteredOptions}
          onChange={(event, value) => {
            if (value.length > 5) return;
            item.answer = JSON.stringify(value);
            setQuestionsState([...questionsState]);
          }}
          renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
        />
      );
    }

    if (type === QuestionType.Select) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const customOptions = options || ({} as any);
      const parsedAnswer = getParsedAnswers(questionsState, answer);
      const option = options?.[parsedAnswer]?.[0];

      if (
        (section === '50' && item.id === '206') ||
        (section === '51' && item.id === '209') ||
        (section === '31' && item.id === '209') || //pre-construction
        (section === '32' && item.id === '212')
      ) {
        mappedStates
          .map(({ name }: Country) => name)
          .forEach((item: string) => {
            return (customOptions[item as string] = []);
          });
      }

      questionItem = (
        <>
          <AutoComplete
            className="mb-16"
            id="combo-box-demo"
            value={parsedAnswer}
            options={customOptions ? (Object.keys(customOptions) as string[]) : []}
            onChange={async (_: ChangeEvent<unknown>, value: string | null) => {
              if (id === '206') {
                const foundCityState = questionsState.find(({ id }) => id === '205');
                const foundPostalCodeState = questionsState.find(({ id }) => id === '207');
                if (foundCityState) foundCityState.answer = '';
                if (foundPostalCodeState) foundPostalCodeState.answer = '';
              }

              if (id === '209') {
                const foundCityState = questionsState.find(({ id }) => id === '208');
                const foundPostalCodeState = questionsState.find(({ id }) => id === '210');
                if (foundCityState) foundCityState.answer = '';
                if (foundPostalCodeState) foundPostalCodeState.answer = '';
              }

              item.answer = value as string;
              setQuestionsState([...questionsState]);
            }}
            renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
          />
          {option && option.hasOwnProperty('template') && (
            <>
              {(function () {
                return getEditor(option, allowEditorToEdit);
              })()}
            </>
          )}
        </>
      );
    }
    if (type === QuestionType.SelectDate) {
      const option = options?.[''][0];
      questionItem = (
        <>
          <DatePicker
            className="mb-16"
            format={dateFormat ?? 'MM/dd/yyyy'}
            onChange={(date) => {
              handleClearErrorById(id as string);
              if (item.question === 'Inspection Date:') {
                return addDateRelationForInspection(item, date);
              }

              item.answer = getDateWithoutTimeZone(date) ?? '';
              setQuestionsState([...questionsState]);
            }}
            value={
              getParsedAnswers(questionsState, answer)
                ? checkIfDate(toDateWithTimeZone(getParsedAnswers(questionsState, answer)))
                : null
            }
            placeholder={dateFormat ?? 'MM/dd/yyyy'}
            error={isError}
            maxDate={item.id === '138' ? new Date() : undefined}
          />
          {option && getEditor(option, allowEditorToEdit)}
        </>
      );
    }
    if (type === QuestionType.SelectTime) {
      questionItem = (
        <TimePicker
          clearable
          className="mb-16"
          ampm
          format="hh:mm a"
          onChange={(date) => {
            handleClearErrorById(id as string);
            if (!date) {
              item.answer = '2018-01-20T00:00:00.000Z';
              setQuestionsState([...questionsState]);
              return;
            }
            if (item.question === 'Time of inspection:') {
              return addDateRelationForInspection(item, date);
            }
            item.answer = getDateWithoutTimeZone(date) ?? '';
            setQuestionsState([...questionsState]);
          }}
          value={answer ? toDateWithTimeZone(answer) : null}
          TextFieldComponent={TimeInput}
          placeholder="HH:MM"
          error={isError}
        />
      );
    }
    if (type === QuestionType.RichEditor) {
      questionItem = (
        <div className="mb-16">
          <ReportRichEditor
            hasImage
            addRef={addRef}
            isObservation={reportType === ReportTypesEnum.PreConstruction || allowEditorToEdit}
            value={answer}
            onChange={(value: string) => {
              item.answer = value;
              setQuestionsState([...questionsState]);
            }}
          />
        </div>
      );
    }
    if (type === QuestionType.DisabledQuestionData) {
      // TODO: it is temporary quick fix, need to be removed after the design is ready
      questionItem = (
        <div className="mb-16">
          <Typography
            variant="body1"
            style={{
              fontWeight: 'bold',
            }}
          >
            {question}
          </Typography>
        </div>
      );
    }

    if (type === QuestionType.DisabledQuestionData) {
      // TODO: it is temporary quick fix, need to be removed after the design is ready
      return <div>{questionItem}</div>;
    } else {
      return (
        <QuestionLabel
          question={question}
          hidden={hidden}
          onClickLookBack={handleOpenLookBack}
          {...{ required, lookBack, id }}
        >
          {questionItem}
        </QuestionLabel>
      );
    }
  };

  const setupQuestions = (selectedQuestions: Questions[], isPermit?: boolean) => {
    return (
      <>
        {selectedQuestions?.map((item: Questions) => {
          const { id, question, answer, options } = item;
          const parsedAnswer = getParsedAnswers(questionsState, answer);
          const isPermitSection = id === '184' && answer === ConditionsAnswers.Yes;
          return (
            <div key={id} className={clsx(isPermit && styles.permit)}>
              {question && getQuestions(item)}
              {isPermitSection && (
                <Button variant="contained" className="mb-24" onClick={addPermit}>
                  Add permit
                </Button>
              )}
              {answer && options && setupQuestions(options[parsedAnswer] as Questions[], isPermitSection)}
              {isPermit && (
                <DeleteIcon className={styles.deleteIcon} onClick={() => handleDeletePermit(id as string)} />
              )}
            </div>
          );
        })}
      </>
    );
  };
  return { setupQuestions };
};
